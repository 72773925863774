import request from '../request'

export function fetchConfig() {
    return request({
        url: '/Api/InternVip/getConfig',
    })
}

export function setConfig(data) {
    return request({
        url: '/Api/InternVip/setConfig',
        method: 'post',
        data,
    })
}

export function signUp(data) {
    return request({
        url: '/Api/InternVip/joinClass ',
        method: 'post',
        data,
    })
}

export function fetchAnalysis(data) {
    return request({
        url: '/Api/InternVip/getStats ',
        method: 'post',
        data,
    })
}