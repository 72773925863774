
<template>
    <div class="page">
         <div class="header">后台管理</div>

         <a-skeleton v-if="loading" />

        <div v-else>

            <div class="list">
            <div class="item" v-for="item in list" :key="item.label">

                <div class="title">{{ item.label }}</div>

                <div class="section">
                    <div class="label">开启活动</div>
                    <a-switch v-model="item.open" />
                </div>

                <div class="label">最大人数</div>

                <div v-if="Array.isArray(item.options)">
                    <div class="option" v-for="option in item.options" :key="option.label">
                        <div>{{ option.label }}</div>
                        <a-input-number v-model="option.max" :min="1" />
                    </div>
                </div>
            </div>
         </div>



         <a-button type="primary" block @click="submit" size="large" :loading="saving">提交</a-button>
            
        </div>
 
    </div>
</template>


<script>
    import { fetchConfig,setConfig } from '../../api/sign-up';
    export default {

        data() {
            return {
                list: [],
                loading: false,
                saving: false,
            }
        },

        mounted() {
            this.getList();
        },

        methods: {
            getList(){ 
                this.loading = true;
                fetchConfig().then(res => {
                    console.log('res',res)
                    if (res && Array.isArray(res.list)) {
                        this.list = res.list;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },

            submit() {
                console.log(this.list)
                this.saving = true;
                const data = new FormData();
                data.append('config', JSON.stringify(this.list))
                
                setConfig(data).then(res => {
                    console.log('res',res)
                    if (res._status === 1) {
                        this.$message.success(res.info);
                    }
                }).finally(() => {
                    this.saving = false;
                })
            }
        }
    }
</script>


<style scoped>
    .page {
        padding: 16px;
        background-color: #f0f0f0;
        min-height: 100vh;
    }
    .header {
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 16px;
    }

    .item {
        background-color: #fff;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 16px;
    }
    .title {
        font-weight: bold;
        font-size: 1.1em;
        border-bottom: 1px solid #f0f0f0;
        color: #1890ff;
        margin-bottom: 12px;
        padding-bottom: 12px;
    }

    .section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        margin-bottom: 12px;
    }

    .label {
        font-weight: bold;
    }

    .option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
    }


</style>